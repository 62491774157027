<template>
  <div>
    <organization-user-form
      :entity="entity"
      :disabled="true"
    >
      <b-col md="6">
        <text-input
          id="business-email"
          name="Business Email"
          rules="required|email"
          :text.sync="entity.business_email"
        />
      </b-col>
    </organization-user-form>
  </div>
</template>

<script>
import OrganizationUserForm from '@/common/components/UserEntities/OrganizationUserForm.vue'
import Reclaims from '@/common/compositions/Reclaims/ReclaimsAPI'

export default {
  components: { OrganizationUserForm },
  data() {
    return {
      entity: {},
    }
  },
  setup() {
    const { showRequest } = Reclaims()
    return { showRequest }
  },
  created() {
    this.getEntity()
  },
  methods: {
    getEntity() {
      this.showRequest(this.$route.params.id).then(res => {
        this.entity = res.data.data
      })
    },
  },
}
</script>

<style>

</style>
